import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c(VFadeTransition, [_vm.editable && _vm.contact && !_vm.contact.title && !_setup.salutation ? _c('AlertArea', {
    staticClass: "mb-4",
    attrs: {
      "type": "info",
      "no-margin": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm._v("\n        " + _vm._s(_setup.$t('info.notSetSalutationContact')) + "\n      ")];
      },
      proxy: true
    }], null, false, 767311167)
  }, [_vm._v(" "), _c('TextButton', {
    attrs: {
      "value": _setup.$t('action.doSet')
    },
    on: {
      "click": _setup.onClickAddSalutation
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('VerticalTable', {
    attrs: {
      "headers": [{
        text: _setup.$t('name'),
        value: 'name',
        half: true
      }, {
        text: _setup.$t('salutation'),
        value: 'title',
        half: true
      }, {
        text: _setup.$t('email'),
        value: 'email'
      }, {
        text: _setup.$t('accountName'),
        value: 'accountName'
      }, {
        text: _setup.$t('department'),
        value: 'department',
        half: true
      }, {
        text: _setup.$t('jobTitle'),
        value: 'job_title',
        half: true
      }, {
        text: _setup.$t('syncStatus'),
        value: 'syncStatus',
        disabled: !_setup.isCrmInstanceContact(_vm.contact),
        help: _setup.$t('extension.aboutSyncStatus'),
        link: _setup.aboutSyncStatusLink
      }],
      "item": _vm.contact,
      "dummy": _vm.dummy
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(item) {
        return [_setup.isCrmInstanceContact(item) && item.id ? _c('LinkText', {
          attrs: {
            "to": "/contact/".concat(item.id),
            "text": _vm.$fullName(item),
            "external": ""
          }
        }) : [_vm._v("\n        " + _vm._s(_vm.$fullName(item)) + "\n      ")]];
      }
    }, {
      key: "item.title",
      fn: function fn(item) {
        return [item.title ? _c('div', [_vm._v(_vm._s(item.title))]) : _vm.editable ? _c('Input', {
          attrs: {
            "value": _setup._salutation,
            "placeholder": _setup.$t('notSet'),
            "dense": ""
          },
          on: {
            "input": function input($event) {
              return _setup.onChangeSalutation($event);
            }
          }
        }) : _setup._salutation ? _c('div', [_vm._v(_vm._s(_setup._salutation))]) : _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n        " + _vm._s(_setup.$t('notSet')) + "\n      ")])];
      }
    }, {
      key: "item.accountName",
      fn: function fn(_ref) {
        var _account$name;
        var crm_company_name = _ref.crm_company_name,
          account = _ref.account;
        return [_setup.isCrmInstanceContact(_vm.contact) && account !== null && account !== void 0 && account.id ? _c('LinkText', {
          attrs: {
            "to": "/accounts/".concat(account === null || account === void 0 ? void 0 : account.id),
            "text": account === null || account === void 0 ? void 0 : account.name,
            "external": ""
          }
        }) : account !== null && account !== void 0 && account.name || crm_company_name ? _c('span', [_vm._v("\n        " + _vm._s((_account$name = account === null || account === void 0 ? void 0 : account.name) !== null && _account$name !== void 0 ? _account$name : crm_company_name) + "\n      ")]) : _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n        " + _vm._s(_setup.$t('notSet')) + "\n      ")])];
      }
    }, {
      key: "item.syncStatus",
      fn: function fn() {
        return [_setup.isCrmInstanceContact(_vm.contact) ? _c('div', {
          staticClass: "d-flex align-center"
        }, [_setup.syncStatusIcon ? _c(VIcon, {
          staticClass: "mr-1",
          attrs: {
            "size": "20",
            "color": _setup.syncStatusColor
          }
        }, [_vm._v("\n          " + _vm._s(_setup.syncStatusIcon) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('div', {
          staticClass: "d-flex align-center flex-wrap"
        }, [_c('div', {
          staticClass: "text-body-1 mr-2"
        }, [_vm._v("\n            " + _vm._s(_setup.syncStatusText) + "\n          ")]), _vm._v(" "), !_vm.noSyncStatusDetail && _setup.syncStatusDetail ? _c('TextButton', {
          attrs: {
            "value": _setup.syncStatusDetail.text,
            "x-small": ""
          },
          on: {
            "click": _setup.syncStatusDetail.callback
          }
        }) : _vm._e()], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };