import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.$slots["header.column"] ? _c('div', {
    staticClass: "vertical-table-column-header d-flex flex-col flex-wrap"
  }, [_c('div', {
    staticClass: "d-flex tr flex-grow-1",
    class: {
      'flex-column': _setup.isSmallLayout
    },
    staticStyle: {
      "min-width": "100%"
    }
  }, [_c('div', {
    staticClass: "th flex-shrink-0 pl-4 pr-1",
    class: {
      'py-1': _setup.isSmallLayout,
      'py-2': !_setup.isSmallLayout
    },
    style: {
      display: _setup.isSmallLayout ? 'none' : undefined
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "td text-body-1 py-2 px-4"
  }, [_vm._t("header.column")], 2)])]) : _vm._e(), _vm._v(" "), _setup.props.headers.length ? _c('div', {
    directives: [{
      name: "resize-observer",
      rawName: "v-resize-observer",
      value: _setup.onResize,
      expression: "onResize"
    }],
    staticClass: "vertical-table d-flex flex-col flex-wrap"
  }, _vm._l(_setup.filteredHeaders, function (header) {
    var _ref2, _header$getter, _header$getter2;
    return _c('div', {
      key: header.value,
      staticClass: "d-flex tr flex-grow-1",
      class: {
        'flex-column': _setup.isSmallLayout
      },
      style: "min-width: ".concat(header.half ? 50 : 100, "%;")
    }, [_c('div', {
      staticClass: "th text-subtitle-2 secondary-text--text color-background flex-shrink-0 pl-4 pr-1",
      class: {
        'py-1': _setup.isSmallLayout,
        'py-2': !_setup.isSmallLayout
      },
      style: {
        width: _setup.isSmallLayout ? '100%' : ''
      }
    }, [_vm._v("\n        " + _vm._s(header.text) + "\n        "), header.info || header.warning || header.help ? _c('FloatingTooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [header.link ? _c('button', _vm._g({
            staticClass: "tw-btn tw-btn-icon-x-small tw-btn-icon-primary !tw-inline-flex tw-align-middle",
            attrs: {
              "type": "button"
            },
            on: {
              "click": function click($event) {
                return _vm.$open(header.link);
              }
            }
          }, on), [header.info ? _c('mdi-information', {
            staticClass: "tw-size-4"
          }) : _vm._e(), _vm._v(" "), header.warning ? _c('mdi-alert', {
            staticClass: "tw-size-4"
          }) : _vm._e(), _vm._v(" "), header.help ? _c('mdi-help-circle', {
            staticClass: "tw-size-4"
          }) : _vm._e()], 1) : _c('span', _vm._g({
            staticClass: "tw-ml-[2px] tw-inline-block tw-align-middle tw-text-info"
          }, on), [header.info ? _c('mdi-information', {
            staticClass: "tw-size-4"
          }) : _vm._e(), _vm._v(" "), header.warning ? _c('mdi-alert', {
            staticClass: "tw-size-4"
          }) : _vm._e(), _vm._v(" "), header.help ? _c('mdi-help-circle', {
            staticClass: "tw-size-4"
          }) : _vm._e()], 1)];
        }
      }], null, true)
    }, [_vm._v(" "), _c('span', {
      staticClass: "text-body-2"
    }, [_vm._v("\n            " + _vm._s(header.info || header.warning || header.help) + "\n          ")])]) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "td text-body-1 py-2 px-4"
    }, [_vm.dummy || header.dummy ? _c('SkeletonLoader', {
      attrs: {
        "text": "body-1"
      }
    }) : _vm.$scopedSlots["item.".concat(header.value)] ? [_vm._t("item.".concat(header.value), null, null, _setup.props.item)] : _vm.$scopedSlots.item && (_vm.result = (_ref2 = _setup.props.item && ((_header$getter = header.getter) === null || _header$getter === void 0 ? void 0 : _header$getter.call(header, _setup.props.item))) !== null && _ref2 !== void 0 ? _ref2 : _setup.get(_setup.props.item, header.value)) ? [_vm._t("item", null, null, {
      item: _setup.props.item,
      value: header.value,
      text: _vm.result
    })] : _setup.props.item && (_vm.result = (_header$getter2 = header.getter) === null || _header$getter2 === void 0 ? void 0 : _header$getter2.call(header, _setup.props.item)) ? [_vm._v("\n          " + _vm._s(_vm.result) + "\n        ")] : _setup.get(_setup.props.item, header.value) ? [_vm._v("\n          " + _vm._s(_setup.get(_setup.props.item, header.value)) + "\n        ")] : _c('span', {
      staticClass: "text-body-2 disabled-text--text"
    }, [_vm._v("\n          " + _vm._s(_vm.$t('notSet')) + "\n        ")])], 2)]);
  }), 0) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };