/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ShippingInfoArea.vue?vue&type=template&id=529e9844"
import script from "./ShippingInfoArea.vue?vue&type=script&lang=ts&setup=true"
export * from "./ShippingInfoArea.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextButton: require('/code/src/components/button/TextButton.vue').default,AlertArea: require('/code/src/components/AlertArea.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,Input: require('/code/src/components/input/Input.vue').default,VerticalTable: require('/code/src/components/table/VerticalTable.vue').default})
